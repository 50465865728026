<template>
    <div class="">
        <div class="">
            <p class="">
                Please wait…
            </p>
            <p>Your request is currently being processed.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SuccessfulPayment',
    metaInfo: {
        title: 'Great success!'
    }
}
</script>
