<template>
    <radius-loader v-if="loading" />
</template>

<script>
import Api from '@/helpers/api/index.js'
import RadiusLoader from '@/components/common/RadiusLoader.vue'

export default {
    components: {
        RadiusLoader
    },
    data() {
        return {
            loading: false
        }
    },
    created() {
        this.getCallback()
    },
    methods: {
        async getCallback() {
            try {
                this.loading = true
                const { data } = await Api.post('/api/order-batch/pay/stripe/proxy-checkout', { batchid: this.$route.query.batchid })
                window.location.href = data.redirect_to
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
